<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      >บันทึกการชำระเงิน</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="10">
          <!-- <v-text-field label="ยอดชำระ" outlined dense background-color="white" v-model="form.a" readonly/> -->
          <div class="text-h4 mb-5">
            ยอดชำระ : {{ form.amount | numeral('0,0.00') }} บาท
          </div>
          <!-- <v-text-field label="วันที่ชำระ" outlined dense background-color="white" v-model="form.b" prepend-inner-icon="mdi-calendar"/> -->
          <DatePicker
            label="วันที่ชำระ"
            v-model="form.date"
            @onChange="val => (form.date = val)"
            outlined
            dense
            icon="mdi-calendar"
          />
          <v-select
            class="my-5"
            label="วิธีการชำระ"
            v-model="form.type"
            :items="payment_type"
            outlined
            dense
            background-color="white"
            :hide-details="!$v.form.pay_type.$error"
            :error-messages="$v.form.pay_type.$error ? $error_text : ''"
          ></v-select>
          <v-select
            v-if="form.type == 2"
            label="ธนาคาร"
            v-model="form.reference"
            outlined
            dense
            background-color="white"
            :items="reference"
          ></v-select>
          <v-textarea
            label="รายละเอียด"
            v-model="form.note"
            outlined
            dense
            background-color="white"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ปิด
      </v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as moment from 'moment';
import {
  required,
  maxLength,
  minLength,
  numeric,
  minValue
} from 'vuelidate/lib/validators';
import DatePicker from '@/components/DatePicker.vue';
export default {
  props: {
    amount: {
      type: Number
    },
    dialogPay: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DatePicker
  },
  validations: {
    form: {
      pay_type: { required }
    }
  },
  data() {
    return {
      form: {
        amount: 0,
        date: moment()
          .set({ hour: 0, minute: 0, second: 0 })
          .format('YYYY-MM-DDTHH:mm:ss'),
        type: '',
        reference: '',
        note: '',
        pay_type: ''
      },
      payment_type: [
        { text: 'เงินสด', value: 1 },
        { text: 'โอน', value: 2 },
        { text: 'บัตรเครดิต', value: 3 },
        { text: 'อื่นๆ', value: 4 }
      ],
      reference: [],
      loading: false,

      validations: {
        form: {
          pay_type: { required }
        }
      },
      errors: {
        pay_type: ''
      }
    };
  },
  created() {
    this.getBankAccount();
  },
  watch: {
    amount(value) {
      console.log('net_price', value);
      this.form.amount = value;
    }
  },
  methods: {
    save() {
      this.$v.form.$touch();
      // if (this.$v.form.$invalid) return;
      this.valid = true;
      this.errors = {};
      this.loading = true;
      if (this.form.type == '') {
        this.valid = false;
        this.loading = false;
      }
      console.log(this.errors);
      if (this.valid) {
        this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
          async result => {
            if (result.isConfirmed) {
              console.log(this.form);
              this.form = { ...this.form, expense_id: this.$route.params.id };
              let body = {
                token: this.$jwt.sign(this.form, this.$privateKey, {
                  noTimestamp: true
                })
              };
              await this.$axios
                .post(`${this.$baseUrl}/expense/pay_expense`, body)
                .then(res => {
                  this.$alertSuccess({ title: res.status });
                  if (!this.isUpdate) this.$emit('successPay');
                })
                .catch(err => {
                  console.log('err', err);
                  this.$alertServerError({ title: err.error_message });
                });
              console.log('save()');
            }
            this.loading = false;
          }
        );
        this.$v.form.$reset();
      }
    },
    close() {
      this.$v.form.$reset();
      this.$emit('onClose');
    },
    async getBankAccount() {
      let payload = {
        branch_id: this.$store.state.selected_branch._id
      };
      let body = {
        token: this.$jwt.sign(payload, this.$privateKey, { noTimestamp: true })
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_bank_account`, body)
        .then(async res => {
          this.reference = res.result;
        })
        .catch(err => {
          this.reference = [];
        });
      this.form.amount = this.amount;
    }
  }
};
</script>
