div<template>
  <div class="px-5">
    <v-row>
      <v-col cols="3">
        <div class="text-h4 primary--text">ค่าใช้จ่ายรายจ่าย</div>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา ชื่อผู้จำหน่าย"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getData"
          item-key="_id"
        >
        </v-text-field>
      </v-col>
      <v-col cols="3"></v-col>
      <v-col cols="2" class="text-right">
        <v-btn
          color="success"
          small
          outlined
          class="ml-5 px-10"
          @click="download_excel()"
          >ดาวน์โหลด <v-icon right small>mdi-download</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-toolbar height="150px" dense flat rounded outlined class="my-5 pt-3 pb-15">
      <v-row>
         <v-col cols="2">
            <v-select
          v-model="filter.category_id"
          label="หมวดหมู่หลัก"
          :items="category_id"
          item-value="value"
          item-text="text"
          outlined
          rounded
          dense
          hide-details
          @change="getSubcategory"
              />
          </v-col>
      <v-col cols="2">
        <v-autocomplete
          label="หมวดหมู่ย่อย"
          :items="sub_category_id"
          item-text="name"
          item-value="_id"
          outlined
          rounded
          dense
          hide-details
          autocomplete="off"
          v-model="filter.sub_category_id"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
              <v-select
                label="ช่วงเวลา"
                v-model="timeRangeType"
                :items="timeRangeTypes"
                item-value="value"
                item-text="text"
                outlined
                rounded
                dense
                hide-details
              >
              </v-select>
      </v-col>
     <v-col cols="2">
        <DatePicker
          v-if="timeRangeType == 1"
          label="วันเริ่มต้น"
          :value="filter.start_date"
          @onChange="(val) => (filter.start_date = val)"
          hideDetails
          outlined
          rounded
          dense
        />
        <v-menu
              v-else-if="timeRangeType == 2"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="filter.start_date ? getMonth(filter.start_date) : ''"
                  label="เดือน"
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  hideDetails
                  outlined
                  rounded
                  dense
                  readonly
                >
                </v-text-field>
              </template>
              <v-date-picker
                class="hidden-sm-and-down"
                v-model="month"
                type="month"
                no-title
                scrollable
                locale="th"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                <v-btn text color="primary" @click="onSaveMonth(month)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
     </v-col>

      <v-col cols="2">
        <DatePicker
          v-if="timeRangeType == 1"
          label="ถึงวันที่"
          :value="filter.end_date"
          @onChange="(val) => (filter.end_date = val)"
          hideDetails
          outlined
          rounded
          dense
        />
      </v-col>
     <v-col cols="2">
      <v-btn color="primary" small class="float-right" @click="getData()"
              >แสดงรายงาน <v-icon right>mdi-magnify</v-icon></v-btn
            >
     </v-col>
    <v-col cols="10"></v-col>
     <v-col cols="2">
      <v-btn color="primary" small outlined class="float-right" @click="add()"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มค่าใช้จ่าย</v-btn
      >
     </v-col>
      </v-row>
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="viewDetali"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ formatDate(item.date_of_issue) }}
      </template>
      <template v-slot:[`item.net_price`]="{ item }">
        {{ item.net_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.payment`]="{ item }">
        {{ item.payment == 0 ? 'ยังไม่ดำเนินการ' : '' }}
        {{ item.payment == 1 ? 'เงินสด' : '' }}
        {{ item.payment == 2 ? 'โอน' : '' }}
        {{ item.payment == 3 ? 'บัตรเครดิต' : '' }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <menu-ship-document
          small
          v-model="item.status"
          :id="item._id"
          :statusId="item.status"
          @success="getData()"
          :docTypeId="docTypeId"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item
                dense
                link
                :class="
                  item.status == 1 || item.status == 2 ? 'disable-edit' : ''
                "
                @click="edit(item)"
              >
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item dense link @click="payment(item)">
                <v-list-item-title>จ่ายเงิน</v-list-item-title>
              </v-list-item> -->
              <v-list-item dense link @click="reset(item)">
                <v-list-item-title>รีเซ็ท</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Detail from './Detail.vue';
import DatePicker from '@/components/DatePicker.vue';
import * as moment from 'moment';

export default {
  components: {
    DatePicker,
    Detail,
  },

  data: () => ({
    sub_category_id: [],
    docTypeId: 5,
    search: '',
    selected: [],
    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 50,
    list: [],
    car_count: 1,
    headers: [
      { text: '#', value: '_id', filterable: false, sortable: false, width: 0},
      { text: 'วันที่', value: 'date_of_issue' },
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อผู้จำหน่าย', value: 'dealer_name' },
      { text: 'ชื่อโปรเจ็ค', value: 'project_name', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'net_price', filterable: false },
      { text: 'การชำระ.', value: 'payment', filterable: false },
      { text: 'สถานะ', value: 'status', filterable: false, align: 'center' },
      { text: '', value: 'actions', sortable: false, filterable: false },
    ],
    timeRangeType: 3,
    timeRangeTypes: [
      { value: 3, text: 'วันนี้' },
      { value: 0, text: 'เดือนปัจจุบัน' },
      { value: 2, text: 'เดือนก่อน' },
      { value: 1, text: 'เลือกช่วงวันที่' },
    ],
    filter: {
      branch_id: null,
      item_per_page: 50,
      page: 1,
      start_with: '',
      category_id: '',
      sub_category_id: '',
      start_date: moment().add(-0, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      car_count: 1,
    },
    status: [
      { text: 'รอดำเนินการ', value: 0 },
      { text: 'เปิดบิลแล้ว', value: 1 },
      { text: 'ยกเลิก', value: 2 },
    ],
    category_id: [
      { text: 'ทั้งหมด', value: '' },
      { text: 'พรบ', value: 'PC1' },
      { text: 'ประกันภัย', value: 'PC2' },
      { text: 'ขนส่ง', value: 'PC3' },
      { text: 'ทั่วไป', value: 'PC4' },
      { text: 'ค่าใช้จ่ายบริษัท', value: 'PC5' },
    ],
  }),

  created() {
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
    this.getSubcategory();
  },

  methods: {
    async getSubcategory() {
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      this.$axios
        .post(`${this.$baseUrl}/expense_category/get_sub_category_list`, body)
        .then(async (res) => {
          this.sub_category_id = [{ _id: '', name: 'ทั้งหมด' }, ...res.result];
        });
    },
    add() {
      this.$router.push({ name: 'expenses-expenditure-create' });
    },
    edit(item) {
      if (item.status == 1 || item.status == 2) {
        return;
      } else {
        this.$router.push({
          name: 'expenses-expenditure-update',
          params: { id: item._id },
        });
      }
    },
    viewDetali(item) {
      this.$router.push({
        name: 'expenses-expenditure-update',
        params: { id: item._id },
      });
    },
    reset(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            console.log('reset()');
            let body = {
              token: this.$jwt.sign(
                { expense_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/expense/reset_expense`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    payment(item) {
      this.$router.push({
        name: 'expenses-expenditure-update',
        params: { id: item._id },
        query: { payment: true },
      });
    },
    async getData() {
      this.filter.end_date = moment(this.filter.end_date)
        .set({ hour: 23, minute: 59, second: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/expense/get_expense`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    async download_excel() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios({
        method: 'post',
        url: `${this.$baseUrl}/expense/download_expense`,
        responseType: 'blob',
        data: body,
      })
        .then((response) => {
          if (
            moment(this.filter.start_date).format('DDMMYYYY') !=
            moment(this.filter.end_date).format('DDMMYYYY')
          ) {
            this.forceFileDownload(
              response,
              'ค่าใช้จ่ายรายจ่าย' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '_' +
                moment(this.filter.end_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          } else {
            this.forceFileDownload(
              response,
              'ค่าใช้จ่ายรายจ่าย' +
                moment(this.filter.start_date, 'YYYY-MM-DDTHH:mm:ss').format(
                  'DD-MM-YYYY'
                ) +
                '.xlsx'
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
    formatDate(date) {
      if (!date) return '';

      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    },
    onSaveMonth(date) {
      this.$refs.menu.save(date);
      this.filter.start_date = moment(date, 'YYYY-MM')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.filter.end_date = moment(date, 'YYYY-MM')
        .endOf('month')
        .format('YYYY-MM-DD');
      console.log(this.filter);
    },
    getMonth(date) {
      return moment(date).format('YYYY-MM');
    },
  },
  watch: {
    timeRangeType: function (value, oldValue) {
      console.log(value);
      if (value == 0) {
        // 0 = เดือนปัจจุบัน
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
        console.log(this.filter);
      } else if (value == 1) {
        // 1 = เลือกช่วงวันที่
        this.filter.start_date = moment().startOf('month').format('YYYY-MM-DD');
        this.filter.end_date = moment().endOf('month').format('YYYY-MM-DD');
        console.log(this.filter);
      } else if (value == 2) {
        // 2 = เดือนก่อน
        this.filter.start_date = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        this.filter.end_date = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        this.month = moment().subtract(1, 'months').format('YYYY-MM');
        console.log(this.filter);
      } else if (value == 3) {
        // 3 = วันนี้
        this.filter.start_date = moment().format('YYYY-MM-DD');
        this.filter.end_date = this.filter.start_date;
        console.log(this.filter);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.disable-edit {
  cursor: default;
  .v-list-item__title {
    color: darkgray;
  }
}
</style>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>

